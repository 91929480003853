<template>
  <b-sidebar
    id="sale-detail-sidebar"
    :visible="isSaleDetailSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sale-detail-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-modal
        id="modal-scrollable"
        scrollable
        title="Credit History"
        ok-only
        ok-title="Close"
      >

        <app-timeline v-if="creditPaymentAmountHistory(purchaseModel)">
          <!-- :time="item.createdAt" -->
          <app-timeline-item
            v-for="(item, index) in creditPaymentAmountHistory(purchaseModel)"
            :key="index"
            :title="`${item.amount}`"
            :subtitle="item.createdAt"
            variant="success"
          />
        </app-timeline>

      </b-modal>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Sale detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <b-overlay
        variant="white"
        :show="loadingReturningg"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >

        <section class="invoice-preview-wrapper">

          <b-row
            v-if="purchaseModel"
            class="invoice-preview"
          >

            <!-- Col: Left (Invoice Container) -->
            <b-col
              cols="12"
              xl="9"
              md="8"
            >
              <b-card
                no-body
                class="invoice-preview-card"
              >
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">

                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper">
                        <!-- <h3 class="text-primary invoice-logo">
                        Sale details
                      </h3> -->
                        <b-badge
                          :variant="buildPaymentStatusColor(purchaseModel.creditStatus)"
                          class="badge-glow"
                        >
                          {{ buildPaymentStatusText(purchaseModel.creditStatus) }}
                        </b-badge>
                      </div>
                      <!-- <p class="card-text mb-25">
                  Office 149, 450 South Brand Brooklyn
                </p>
                <p class="card-text mb-25">
                  San Diego County, CA 91905, USA
                </p>
                <p class="card-text mb-0">
                  +1 (123) 456 7891, +44 (876) 543 2198
                </p> -->
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <h4 class="invoice-title">
                        Invoice
                        <span class="invoice-number">#{{ purchaseModel.id }}</span>
                      </h4>
                      <div
                        v-if="isDef(purchaseModel.createdAt)"
                        class="invoice-date-wrapper"
                      >
                        <p class="invoice-date-title">
                          Date of sale:
                        </p>
                        <p class="invoice-date">
                          {{ new Date(purchaseModel.createdAt).toISOString().substr(0, 19).replace('T', ' ') }}
                        </p>
                      </div>
                      <div
                        v-if="isDef(purchaseModel.returnDate)"
                        class="invoice-date-wrapper"
                      >
                        <p class="invoice-date-title">
                          Due Date:
                        </p>
                        <p class="invoice-date">
                          {{ purchaseModel.returnDate }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                <!-- Invoice Client & Payment Details -->
                <b-card-body
                  v-if="purchaseModel.customerName || purchaseModel.customerPhoneNo"
                  class="invoice-padding pt-0"
                >
                  <b-row class="invoice-spacing">

                    <!-- Col: Invoice To -->
                    <b-col
                      cols="12"
                      xl="6"
                      class="p-0"
                    >
                      <h6 class="mb-2">
                        Invoice To:
                      </h6>
                      <h6 class="mb-25">
                        {{ purchaseModel.customerName || '' }}
                      </h6>
                      <p class="card-text mb-25">
                        {{ purchaseModel.customerPhoneNo || '' }}
                      </p>
                    </b-col>

                    <!-- Col: Payment Details -->
                    <b-col
                      xl="6"
                      cols="12"
                      class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                    >
                      <div>
                        <h6 class="mb-2">
                          More Details:
                        </h6>
                        <table>
                          <tbody>
                            <tr>
                              <td class="pr-1">
                                Payment method:
                              </td>
                              <td><span>{{ buildPaymentTypeText(purchaseModel.type) }}</span></td>
                            </tr>
                            <tr v-if="isDef(purchaseModel.chequeNo)">
                              <td class="pr-1">
                                Cheque No:
                              </td>
                              <td><span>{{ purchaseModel.chequeNo }}</span></td>
                            </tr>
                            <tr v-if="purchaseModel.adminName">
                              <td class="pr-1">
                                Sold By:
                              </td>
                              <td>{{ purchaseModel.adminName }}</td>
                            </tr>
                            <tr v-if="purchaseModel.branchName">
                              <td class="pr-1">
                                Sale at:
                              </td>
                              <td>{{ purchaseModel.branchName }}(Branch)</td>
                            </tr>
                            <tr v-if="purchaseModel.note">
                              <td class="pr-1">
                                Note:
                              </td>
                              <td>{{ purchaseModel.note }}</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>

                <!-- Items Section -->
                <b-card-body v-show="!showPrint">

                  <div
                    ref="form"
                    class="repeater-form"
                    :style="{height: trHeight}"
                  >
                    <b-row
                      v-for="(item, index) in purchaseModel.items"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <!-- Item Form -->
                      <ProductSaleBox
                        :value="item"
                        :purchase-items="purchaseModel.items"
                        :index="index"
                        :staticc="true"
                        @remove="returnItem"
                      />
                    </b-row>
                  </div>
                </b-card-body>

                <b-table-lite
                  v-if="showPrint"
                  responsive
                  :items="purchaseModel.items"
                  :fields="['name', 'qty','amount', 'total']"
                >
                  <template #cell(name)="data">
                    <b-card-text class="font-weight-bold mb-25">
                      {{ data.item.item.name }}
                    </b-card-text>
                    <b-card-text class="text-nowrap">
                      {{ data.item.branchName }}
                    </b-card-text>
                  </template>
                  <template #cell(total)="data">
                    <b-card-text class="font-weight-bold mb-25">
                      {{ data.item.qty * data.item.amount }}
                    </b-card-text>

                  </template>
                </b-table-lite>

                <!-- Invoice Description: Total -->
                <b-card-body class="invoice-padding pb-0">
                  <b-row>

                    <!-- Col: Sales Persion -->
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-0 mt-3"
                      order="2"
                      order-md="1"
                    >
                      <b-card-text class="mb-0">
                        <!-- <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">Alfie Solomons</span> -->
                      </b-card-text>
                    </b-col>

                    <!-- Col: Total -->
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-6 d-flex justify-content-end"
                      order="1"
                      order-md="2"
                    >
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            {{ purchaseModel.includeVat ? 'Total before Tax' : 'Total' }}:
                          </p>
                          <p class="invoice-total-amount">
                            {{ calcTotalAmount(purchaseModel).toFixed(2) }}
                          </p>
                        </div>
                        <div
                          v-if="purchaseModel.includeVat"
                          class="invoice-total-item"
                        >
                          <p class="invoice-total-title">
                            {{ purchaseModel.taxL }} [{{ purchaseModel.taxP }}%]
                          </p>
                          <p class="invoice-total-amount">
                            {{ calcVat(purchaseModel).toFixed(2) }}
                          </p>
                        </div>
                        <hr
                          v-if="purchaseModel.includeVat"
                          class="my-50"
                        >
                        <div
                          v-if="purchaseModel.includeVat"
                          class="invoice-total-item"
                        >
                          <p class="invoice-total-title">
                            Total with Tax:
                          </p>
                          <p class="invoice-total-amount">
                            {{ calcTotalPlusVat(purchaseModel).toFixed(2) }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>

              </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col
              cols="12"
              md="4"
              xl="3"
              class="invoice-actions"
            >
              <b-card>

                <!-- Button: Print -->
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  :variant="showPrint ? 'secondary' : 'outline-secondary'"
                  block
                  class="mb-75"
                  @click="printInvoice"
                >
                  {{ showPrint ? 'Back to edit' : 'Print' }}
                </b-button>
                <b-button
                  variant="danger"
                  block
                  :loading="loadingReturningg"
                  :disabled="loadingReturningg"
                  @click="handleReturnItem"
                >
                  The item is returned
                </b-button>

              </b-card>

              <!-- advance credit-->
              <b-card
                v-show="purchaseModel.type == saleTypeField.credit"
                no-body
              >
                <b-card-header>
                  <b-card-title style="font-size: 13px;">
                    Last payed Total
                  </b-card-title>
                  <b-card-title class="text-primary">
                    {{ lastAdvancePayedTotal(purchaseModel) }}
                  </b-card-title>
                  <!-- @click="showCreditHistory" -->
                  <b-link
                    v-b-modal.modal-scrollable
                    style="font-size: 13px;"
                  >
                    More
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="15"
                    />

                  </b-link>
                </b-card-header>
                <b-card-body
                  v-if="purchaseModel.creditStatus !== creditStatusField.payed || onEditing == true"
                  style="    padding: 1rem;"
                >
                  <b-form-group>
                    <b-input-group>
                      <b-form-input
                        id="manufacturer"
                        v-model="purchaseModel.creditPayedAmount"
                        placeholder="Enter Paid Amount (Advance)"
                        type="number"
                        number
                        @change="onAdvanceInputChange"
                      />
                      <b-input-group-append>
                        <b-button
                          :variant=" !((isDef(purchaseModel.creditPayedAmount) ? purchaseModel.creditPayedAmount : 0) >
                            actualTotal(purchaseModel)) ? buildPaymentStatusColor(
                            purchaseModel.creditStatus) : 'danger'"
                          disabled
                          class="btn-icon"
                        >
                          Remain: {{ lastAdvancedPayedAndTodayTotal(purchaseModel) }}
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-row>

                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        block
                        :loading="loadingAddingCreditToHistoy"
                        :disabled="loadingAddingCreditToHistoy || purchaseModel.creditPayedAmount === 0 || isXid(purchaseModel) || !purchaseModel.creditPayedAmount"
                        variant="primary"
                        @click="handleAddCreditToHistory"
                      >
                        Submit
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-body>

              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BInputGroupAppend, BFormInput, BInputGroup, BFormGroup, BModal, BOverlay, BTableLite,
  // BTableLite,
  BCardText, BButton, VBToggle, BSidebar, BLink, BBadge,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { isDefAndNotEmpty, isDef, parseDate } from '@/assets/helpers'
import { creditStatusField, saleModelField, saleTypeField } from '@/api/field_model'
import { Ajax, isApiSuccess, RouteConst } from '@/libs/ajax'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ProductSaleBox from './ProductSaleBox.vue'
import { buildPaymentTypeText, buildPaymentStatusColor, buildPaymentStatusText } from '../../sale/saleHelper'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BBadge,
    BCard,
    BInputGroupAppend,
    BTableLite,
    BFormInput,
    BLink,
    BModal,
    BFormGroup,
    BInputGroup,
    AppTimeline,
    AppTimelineItem,
    BSidebar,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    // BTableLite,
    BCardText,
    BButton,
    ProductSaleBox,

  },
  mixins: [heightTransition],
  model: {
    prop: 'isSaleDetailSidebarActive',
    event: 'update:is-sale-detail-sidebar-active',
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    loadingReturning: {
      type: Boolean,
      default: false,
    },
    isSaleDetailSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      buildPaymentTypeText,
      purchaseModel: this.value,

      loadingReturningg: this.loadingReturning,
      isDef,
      saleTypeField,
      showPrint: false,
      creditStatusField,
      buildPaymentStatusText,
      buildPaymentStatusColor,
      loadingAddingCreditToHistoy: false,
      onEditing: false,
    }
  },
  watch: {
    loadingReturning(v) {
      // console.debug('loadingReturning changed', v)
      this.loadingReturningg = v
    },
    value(v) {
      // console.debug('changeInside saleData', v)
      this.purchaseModel = v

      if (v[saleModelField.includeVat]) {
        this.purchaseModel.includeVat = true
      }

      this.initTrHeight()
      // if (isDefAndNotEmpty(this.purchaseModel.items)) {
      //   for (let i = 0; i < this.purchaseModel.items.length; i += 1) {
      //     this.$refs.form.style.overflow = 'hidden'
      //     this.$nextTick(() => {
      //       this.trAddHeight(this.$refs.row[0].offsetHeight)
      //       setTimeout(() => {
      //         this.$refs.form.style.overflow = null
      //       }, 350)
      //     })
      //   }
      // }
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    isXid(purchaseModel) {
      const creditPayedAmountt = purchaseModel.creditPayedAmount

      const creditAndLast = creditPayedAmountt + this.lastAdvancePayedTotal(purchaseModel)
      return !!(creditAndLast > this.actualTotal(purchaseModel))
    },
    calcTotalPlusVat() {
      return (this.calcTotalAmount(this.purchaseModel) || 0) + (this.calcVat(this.purchaseModel) || 0)
    },
    creditPaymentAmountHistory(purchaseModel) {
      return purchaseModel.creditPaymentAmountHistory
    },
    lastAdvancedPayedAndTodayTotal(purchaseModel) {
      const creditPayedAmountt = isDef(purchaseModel.creditPayedAmount)
        ? purchaseModel.creditPayedAmount
        : 0

      const creditAndLast = creditPayedAmountt + this.lastAdvancePayedTotal(purchaseModel)

      return !(creditAndLast > this.actualTotal(purchaseModel))
        ? (this.actualTotal(purchaseModel) - (creditAndLast) || 0).toFixed(2) || '0'
        : 'Xid'
    },
    lastAdvancePayedTotal(purchaseModel) {
      let total = 0
      // console.debug({ purchaseModel }, '----', this.creditPaymentAmountHistory(purchaseModel))
      if (isDefAndNotEmpty(this.creditPaymentAmountHistory(purchaseModel))) {
        this.creditPaymentAmountHistory(purchaseModel).forEach(element => {
          total += isDef(element.amount)
            ? element.amount
            : 0
        })
      }
      return total
    },
    calcTotalAmount(purchaseModel) {
      let totalAmount = 0
      if (isDefAndNotEmpty(purchaseModel.items)) {
        for (let i = 0; i < purchaseModel.items.length; i += 1) {
          const element = purchaseModel.items[i]

          const qty = (element.qty ? element.qty : 0)

          const amount = element.amount
            ? parseFloat(element.amount || 0)
            : 0
          totalAmount += Number(amount * qty)
        }
      }
      return totalAmount
    },
    calcVat(purchaseModel) {
      const taxPercentage = (purchaseModel[saleModelField.taxPercentage] || 0)
      return taxPercentage !== 0
        ? (this.calcTotalAmount(purchaseModel) * (taxPercentage / 100))
        : this.calcTotalAmount(purchaseModel)
    },
    actualTotal(purchaseModel) {
      return purchaseModel.includeVat ? this.calcTotalPlusVat(purchaseModel) : this.calcTotalAmount(purchaseModel)
    },
    lastAdvancedPayedAndTodayTotalDouble(purchaseModel) {
      const creditPayedAmountt = isDef(purchaseModel.creditPayedAmount)
        ? purchaseModel.creditPayedAmount
        : 0

      const creditAndLast = creditPayedAmountt + this.lastAdvancePayedTotal(purchaseModel)

      return !(creditAndLast > this.actualTotal(purchaseModel))
        ? (this.actualTotal(purchaseModel) - (creditAndLast) || 0) || 0
        : 0
    },
    calcUnpaidAmount(purchaseModel) {
      let unpaid = 0
      if (purchaseModel.creditStatus !== creditStatusField.payed
        || this.saleTypeField === saleTypeField.credit) {
        unpaid = this.lastAdvancedPayedAndTodayTotalDouble(purchaseModel)
      }
      return unpaid
    },
    handleReturnItem() {
      this.$swal({
        title: 'Return sale',
        text: 'Are you sure the item is returned?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, return it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('onDelete', this.purchaseModel)
        }
      })
    },
    refreshData() {
      this.purchaseModel.totalAmount = this.actualTotal(this.purchaseModel)
      this.purchaseModel.unpaidAmount = this.calcUnpaidAmount(this.purchaseModel)

      // check if the paid amount is greater than the totalAmount
      if (this.purchaseModel.unpaidAmount === 0) {
        this.purchaseModel.creditStatus = creditStatusField.payed
      }
    },
    async returnItem(indexx, v) {
      const { purchaseModel } = this
      const purchaseModelCopy = { ...purchaseModel }

      if (isDef(purchaseModel.items) && purchaseModel.items.length === 1) {
        this.$emit('onDelete', purchaseModel)
        return
      }
      const index = purchaseModelCopy.items.indexOf(v)
      purchaseModelCopy.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.loadingReturningg = true
      // remove the item from purchase

      const totalAmount = this.actualTotal(purchaseModelCopy)
      const unpaidAmount = this.calcUnpaidAmount(purchaseModelCopy)
      purchaseModelCopy[saleModelField.unpaidAmount] = unpaidAmount
      purchaseModelCopy[saleModelField.totalAmount] = totalAmount
      purchaseModelCopy[saleModelField.creditStatus] = unpaidAmount === 0 ? creditStatusField.payed : purchaseModelCopy.creditStatus
      // purchaseModelCopy[saleModelField.creditPaymentAmountHistory] = purchaseModelCopy.creditPaymentAmountHistory
      purchaseModelCopy.creditPaymentAmountHistory = JSON.stringify({
        creditPaymentAmountHistory: purchaseModelCopy.creditPaymentAmountHistory,
      })
      try {
        const res = await new Ajax(RouteConst.returnSingleItemFromSaleUrl, {
          body: {
            saleModel: purchaseModelCopy,
            returnAll: false,
            saleItem: v,
          },
        })
        this.loadingReturningg = false
        if (isApiSuccess(res)) {
          this.refreshData()
        }
      } catch (error) {
        this.loadingReturningg = false
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async handleAddCreditToHistory() {
      this.loadingAddingCreditToHistoy = true
      const { purchaseModel } = this
      const purchaseModelCopy = { ...purchaseModel }

      if (isDef(purchaseModelCopy.creditPayedAmount)
        && purchaseModelCopy.creditPayedAmount !== 0 && purchaseModelCopy.creditPayedAmount !== '') {
        purchaseModelCopy.creditPaymentAmountHistory.push({
          amount: purchaseModelCopy.creditPayedAmount,
          createdAt: parseDate(new Date(Date.now())),
        })
      }
      purchaseModelCopy.creditPayedAmount = ''
      try {
        const res = await Ajax(RouteConst.updateCreditPurchaseUrl, {
          body: {
            history: JSON.stringify({
              creditPaymentAmountHistory: purchaseModelCopy.creditPaymentAmountHistory,
            }),
            id: purchaseModelCopy.id,
            unpaidAmount: this.calcUnpaidAmount(purchaseModelCopy),
            status: purchaseModelCopy.creditStatus,
          },
        })
        this.loadingAddingCreditToHistoy = false
        if (isApiSuccess(res)) {
          this.purchaseModel.creditPayedAmount = ''
          this.purchaseModel = purchaseModelCopy
        }
      } catch (error) {
        this.loadingAddingCreditToHistoy = false
        console.debug(error)
      }
    },
    showCreditHistory() {}, // TODO
    onAdvanceInputChange(v) {
      const { purchaseModel } = this
      console.debug('v', v)
      // eslint-disable-next-line no-restricted-globals
      console.debug(isNaN(v))
      // eslint-disable-next-line no-restricted-globals
      if (isDef(v) && isNaN(v) === false && v !== '') {
        purchaseModel.creditPayedAmount = parseFloat(Number(v))
        const creditAndLast = purchaseModel.creditPayedAmount + this.lastAdvancePayedTotal(purchaseModel)
        if (purchaseModel.creditPayedAmount === 0) {
          purchaseModel.creditStatus = creditStatusField.unPayed
        } else if (creditAndLast >= this.actualTotal(purchaseModel)) {
          purchaseModel.creditStatus = creditStatusField.payed
          this.onEditing = true
        } else if (creditAndLast < this.actualTotal(purchaseModel)) {
          purchaseModel.creditStatus = creditStatusField.advance
        }
      } else {
        purchaseModel.creditPayedAmount = 0
        purchaseModel.creditStatus = creditStatusField.advance
      }
    },
    printInvoice() {
      if (this.showPrint) {
        this.showPrint = false
        this.initTrHeight()
      } else {
        this.showPrint = true
        setTimeout(() => {
          window.print()
        }, 200)
      }
    },
  },

}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

</style>

<style lang="scss">
  // .b-sidebar > .b-sidebar-body {
  //   height: auto !important;
  // }

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

#sale-detail-sidebar {
  width:  calc(100%);
  .invoice-preview-wrapper {
        padding: 20px;
  }
  .vs__dropdown-menu {
    max-height: 100% !important;
  }
}</style>

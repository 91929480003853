<template>
  <section class="ecommerce-application-small">
    <div class="list-view product-checkout">
      <div class="checkout-items">
        <b-card
          class="ecommerce-card"
          no-body
        >

          <!-- Product Image -->
          <div class="item-img">
            <b-link>
              <b-img
                :src="image"
                height="70px"
                rounded
                style="width: 70px"
                :alt="`${product.name}-${product.id}`"
              />
            </b-link>
          </div>

          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-0">
                <b-link class="text-body">
                  {{ product.name }}
                </b-link>
              </h6>

            </div>
            <span
              class="mb-1"
              :class="product.branchType === '1' ? 'text-primary' : 'text-success'"
            >{{ product.branchName }} {{ product.branchType === '1' ? '(Warehouse)' : '(Shop)' }} </span>
            <span class="delivery-date text-muted">Price: {{ product.unitPrice }}</span>
          </b-card-body>

        </b-card>
      </div>
    </div>
  </section>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg,
} from 'bootstrap-vue'
import { isDef, noImage } from '@/assets/helpers'

export default {
  components: {
    BCard, BCardBody, BLink, BImg,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    image() {
      return isDef(this.product.image) && this.product.image !== '' ? this.product.image : noImage
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-small.scss';
</style>

<template>

  <div>

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Filter</label>
            <v-select
              v-model="filterBySelected"
              :options="filterOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>

            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Sort</label>
            <v-select
              v-model="sortBySelected"
              :options="sortOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>

            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <!-- <label>Collection</label> -->

          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-overlay
      id="overlay-background"
      :show="secondaryLoading || loading"
      variant="blur"
      :opacity="0.5"
      blur="2px"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search by customer..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refItemListTable"
          v-model="saleListCopy"
          :per-page="7"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="tableColumns"
          :busy="loading"
          primary-key="id"
          :sort-by.sync="sortBy"
          striped
          :show-empty="!loading"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: User -->
          <template #cell(items)="data">
            <span>
              {{ data.item.items ? data.item.items.length : '' }}
            </span>
          </template>

          <template #cell(itemName)="data">
            <span>
              {{ data.item.items && data.item.items.length > 0 ? data.item.items[0].item.name + '-' + data.item.items[0].item.partNo : '' }}
            </span>
          </template>

          <template #cell(type)="data">
            <b-badge
              :variant="buildPaymentStatusColor(data.item.creditStatus)"
              class="badge-glow"
            >
              {{ buildPaymentStatusText(data.item.creditStatus) }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <span @click="showDetail(data.item)">
                <b-button
                  :id="`invoice-row-${data.item.id}-preview-icon`"
                  variant="dark"
                  size="sm"
                >
                  View
                </b-button>
                <!-- <feather-icon

                  icon="EyeIcon"
                  size="16"
                  class="mx-1"
                /> -->

              </span>
              <!-- <b-tooltip
                title="Preview Detail"
                aria-label=""
                :target="`invoice-row-${data.item.id}-preview-icon`"
              /> -->
              <!-- <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                 <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  variant="danger"
                  @click="onDelete(data.item)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    color="danger"
                  />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>-->
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ from }} to {{ to }} of {{ of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <SaleDetail
      :is-sale-detail-sidebar-active.sync="isAddNewItemSidebarActive"
      :value="saleData"
      :loading-returning="loadingReturning"
      @onDelete="deleteSaleData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BCardBody, BCardHeader, BBadge,
  // BLink,
  BOverlay,
  // BTooltip,
  BButton,
  // BBadge,
  // BDropdown,
  // BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
// import store from '@/store'
// import { ref, onUnmounted } from '@vue/composition-api'
import {
  Ajax, getApiData, getWebData, isApiSuccess, RouteConst,
} from '@/libs/ajax'
import Ripple from 'vue-ripple-directive'

import {
  creditStatusField, historyTypeField, saleModelField,
} from '@/api/field_model'
import AuthMixin from '@/mixins/authMixin'
import SaleDetail from './components/SaleDetail.vue'
import { debounce, perPage } from '../../assets/helpers'
import {
  buildPaymentStatusColor,
  buildPaymentStatusText,
} from '../sale/saleHelper'

export default {
  components: {

    BCard,
    BRow,
    BOverlay,
    BBadge,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCol,
    BFormInput,
    // BTooltip,
    BCardBody,
    BCardHeader,
    BTable,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
    SaleDetail,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  data() {
    return {
      loadingReturning: false,
      currentPage: 1,
      buildPaymentStatusColor,
      buildPaymentStatusText,
      historyTypeField,
      isAddNewItemSidebarActive: false,
      tableColumns: [
        { key: 'customerName' },
        { key: 'itemName', label: 'Item Name' },
        { key: 'items' },
        { key: 'totalAmount' },
        { key: 'type', label: 'Status' },
        { key: 'createdAt', label: 'Date of sale' },
        { key: 'actions' },
      ],
      sortBy: 'id',
      saleData: {},
      filterBySelected: {
        label: 'All Items',
        technicalName: '',
        value: '',
      },
      sortBySelected: {
        label: 'Date Added (New to Old)',
        technicalName: saleModelField.createdAt,
        value: 'DESC',
      },
      isSortDirDesc: true,
      perPage,
      searchQuery: '',
      loading: false,
      secondaryLoading: false,
      total: 0,
      filterOptions: [
        {
          label: 'All Items',
          technicalName: '',
          value: '',
        },
        {
          label: 'Payed',
          technicalName: saleModelField.creditStatus,
          value: creditStatusField.payed,
        },
        {
          label: 'Unpaid',
          technicalName: saleModelField.creditStatus,
          value: creditStatusField.unPayed,
        },
        {
          label: 'Credit (Advance)',
          technicalName: saleModelField.creditStatus,
          value: creditStatusField.advance,
        },
      ],
      sortOptions: [
        {
          label: 'Date Added (New to Old)',
          technicalName: saleModelField.createdAt,
          value: 'DESC',
        },
        {
          label: 'Credit Return Date (Old to New)',
          technicalName: saleModelField.returnDate,
          value: 'ASC',
        },
        {
          label: 'Total amount (High to Low)',
          technicalName: saleModelField.totalAmount,
          value: 'DESC',
        },
      ],
      saleListCopy: [],
    }
  },
  computed: {
    refItemListTable() {
      // console.debug(this)
      return this.$refs.refItemListTable
    },
    from() {
      return this.perPage * (this.currentPage - 1) + (this.saleListCopy.length ? 1 : 0)
    },
    to() {
      return this.perPage * (this.currentPage - 1) + this.saleListCopy.length
    },
    of() {
      return this.total
    },
  },
  watch: {
    sortBySelected() {
      this.refItemListTable.refresh()
    },
    filterBySelected() {
      this.refItemListTable.refresh()
    },
    searchQuery: debounce(function (val) {
      const thiss = this
      if (val) {
        // console.debug(this)
        thiss.searchItems({ refresh: true })
      } else {
        thiss.refItemListTable.refresh()
      }
    }, 500),
    currentPage() {
      if (this.searchQuery !== '') {
        this.searchItems({})
      } else {
        this.refItemListTable.refresh()
      }
    },
  },
  methods: {
    async deleteSaleData(purchaseModel) {
      this.loadingReturning = true
      const res = await Ajax(RouteConst.returnAllSalesUrl, {
        body: {
          saleModel: purchaseModel,
          saleItemsList: purchaseModel.items,
        },
      })
      this.loadingReturning = false
      if (isApiSuccess(res)) {
        this.showSuccessMessage('Successfully returned')
        this.isAddNewItemSidebarActive = false
        const index = this.saleListCopy.indexOf(purchaseModel)
        this.saleListCopy.splice(index, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Fail to return',
            icon: 'icon-alert-circle',
            text: 'Please try again',
            variant: 'danger',
          },
        },
        {
          timeout: 2500,
          position: 'bottom-right',
        })
      }
    },
    showDetail(v) {
      this.saleData = v
      this.isAddNewItemSidebarActive = true
    },
    showSuccessMessage(message) {
      this.$swal({
        position: 'bottom-end',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    onDelete(v) {
      // console.debug(v)
      this.$swal({
        title: 'Sure to delete?',
        text: 'Deleting sale items will return',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        // console.debug(result)
        if (result.value) { // isConfirmed
          this.secondaryLoading = true
          await this.deleteSaleData(v)
          this.secondaryLoading = false
        }
      })
    },
    async searchItems({ refresh = false }) {
      this.loading = true
      Ajax(RouteConst.getSearchPaginatedPurchaseUrl, {
        body: {
          page: refresh ? 1 : this.currentPage,
          q: this.searchQuery,
          sellerId: this.legalUserId,
          isCredit: false,
        },
      }).then(res => {
        this.loading = false
        const originalResult = getApiData(res)
        const webData = getWebData(res)
        this.$refs.refItemListTable.localItems = originalResult
        this.total = webData.sqlCount
      })
        .catch(error => {
          console.debug(error)
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching sale list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            timeout: 4000,
            position: 'bottom-right',
          })
        })
    },
    fetchItems(ctx, callback) {
      this.loading = true
      Ajax(RouteConst.getPaginatedPurchaseUrl, {
        body: {
          page: this.currentPage,
          filterBy: this.filterBySelected.label !== 'All Items' ? this.filterBySelected : null,
          sortBy: this.sortBySelected,
          sellerId: this.legalUserId,
        },
      }).then(res => {
        this.loading = false
        const originalResult = getApiData(res)
        const webData = getWebData(res)

        callback(originalResult)
        this.total = webData.sqlCount
      })
        .catch(error => {
          console.debug(error)
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching sale list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            timeout: 4000,
            position: 'bottom-right',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  min-width: 240px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
